var playerInfoList = [];

function onYouTubeIframeAPIReady() {
    let allVideos = document.querySelectorAll('.video');

    allVideos.forEach(function(div, index) {
        let player = createPlayer(div);
        playerInfoList.push(player);
    });
}

function createPlayer(div) {
    let startTime = parseInt(div.dataset.startTime || "0", 10);
    let width = '470';
    let height = '260';
    if(div.dataset.videoId === 'EsVIxFTQhXU'){
        width = '100%';
        height = '100%';
    }

    let playerOptions = {
        width: width,
        height: height,
        videoId: div.dataset.videoId,
        playerVars: {
            autoplay: 1,
            disablekb: 1,
            controls: 0,
            loop: 1,
            rel: 0,
            showinfo: 0,
            iv_load_policy: 3,
            start: startTime
        },
        events: {
            'onStateChange': onPlayerStateChange
        }
    };
    return new YT.Player(div.id, playerOptions);
}

function onPlayerStateChange(event) {
    if (event.data === YT.PlayerState.PLAYING) {
        let currentPlayer = event.target;
        playerInfoList.forEach((player) => {
            if (player !== currentPlayer && player.stopVideo) {
                player.stopVideo();
            }
        });
    }
}


function pageGA(logMessage, gaName, url) {
    if (window.ga) {
        window.ga('send', 'event', '首頁', '點擊', gaName);
    }

    if (url && url !== "") {
        window.location.href = url
    }
}
// cookie
function checkCookie(){
    var cookie = getCookie("eh-expires")
    var cookieWrap  = document.querySelector('.cookie');

    if (cookie !== undefined && Number.isInteger(parseInt(cookie)) && parseInt(cookie) > Date.now()){
        setCookie();
    }else{
        cookieWrap.classList.remove("dn")
        setCookie();
    }
}

checkCookie();

function setCookie() {
    var btnAgree  = document.querySelector('.btn-agree-cookie');
    var cookieWrap  = document.querySelector('.cookie');

    btnAgree.addEventListener("click",function(){
        cookieWrap.classList.add("dn")
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();

        var fulldate = new Date(year + 3, month, day);
        document.cookie = "eh-expires=" + fulldate.getTime() + ";path=/";
    })
}

function getCookie(name){
    var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
    if (arr != null) return unescape(arr[2]); return null;
}

window.eHanlin.dataprovider.index.story(function(data){
    // Storys
    var storyArr = data;
    for(let i=0 ; i < storyArr.result.length ; i++){
        if(storyArr.result[i].img){
            storyArr.result[i].img = storyArr.result[i].img.coffeeReplace(window.eHanlin.platform_env);
        }
        else{
            storyArr.result[i].img = window.eHanlin.platform_env.cdn+'/web/experience/somebody_v1.jpg';
        }
    }
    appendStorys();

    function appendStorys() {
        document.querySelector("#storysSlot").innerHTML = "";
        for (let i = 0; i < storyArr.result.length && i < 20 ; i++) {
            if(storyArr.result[i].awards){
                document.querySelector("#storysSlot").innerHTML += '<div class="slider-items"><div class="student-card special"><div class="student-infos"><img src="'+storyArr.result[i].img+'" alt="'+storyArr.result[i].name+'"><img class="award-winner" src="./img/icon-crown.svg"><div class="main-infos"><div class="student-name">'+storyArr.result[i].name+'</div><div class="student-school">'+storyArr.result[i].school+'</div></div><div class="student-score">'+storyArr.result[i].score+'</div><hr><p>'+storyArr.result[i].story+'</p></div></div></div>';
            }
            else{
                document.querySelector("#storysSlot").innerHTML += '<div class="slider-items"><div class="student-card"><div class="student-infos"><img src="'+storyArr.result[i].img+'" alt="'+storyArr.result[i].name+'"><div class="main-infos"><div class="student-name mt-1">'+storyArr.result[i].name+'</div><div class="student-school">'+storyArr.result[i].school+'</div></div><hr><p>'+storyArr.result[i].story+'</p></div></div></div>';
            }
        }
        tns({
            container: '.card-slider',
            loop: true,
            items: 1,
            slideBy: 'page',
            nav: true,
            navPosition: "bottom",
            controlsContainer: ".card-slider-controls",
            autoplay: true,
            speed: 600,
            autoplayTimeout: 5000,
            autoplayButtonOutput: false,
            mouseDrag: true,
            lazyload: true,
            responsive: {
                1200: {
                        items: 4,
                },
                991: {
                        items: 3,
                },
                768: {
                        items: 2,
                }
            }
        });
    }
});


// scroll to top
document.querySelector('.totop').addEventListener("click",function(){
    document.body.scrollIntoView({behavior: 'smooth', block: 'start'});
    document.documentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    playerInfoList.forEach((player)  => {
        if(player && player.pauseVideo){
            player.pauseVideo();
        }
    });

});

// 步驟滾動效果
function scrollToElementAndPauseVideos(selector) {
    const element = document.querySelector(selector);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        playerInfoList.forEach((player) => {
            if (player && player.pauseVideo) {
                player.pauseVideo();
            }
        });
    }
}

document.querySelector('#learning-button').addEventListener("click", function(e) {
    e.preventDefault();
    scrollToElementAndPauseVideos('#learning');
});

document.querySelector('#test-button').addEventListener("click", function(e) {
    e.preventDefault();
    scrollToElementAndPauseVideos('#test');
});

document.querySelector('#diagnosis-button').addEventListener("click", function(e) {
    e.preventDefault();
    scrollToElementAndPauseVideos('#diagnosis');
});

document.querySelector('#remedy-button').addEventListener("click", function(e) {
    e.preventDefault();
    scrollToElementAndPauseVideos('#remedy');
});

window.onscroll = function() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.querySelector('.totop').style.opacity = "1";
    } else {
        document.querySelector('.totop').style.opacity = "0"
    }

    playerInfoList.forEach((player) => {
        if(player && player.playVideo && player.pauseVideo){
            let playerElement = player.getIframe();
            let rect = playerElement.getBoundingClientRect();
            let windowHeight = window.innerHeight;
            let playPosition = windowHeight * (3 / 4);
            let pausePosition = windowHeight * (1 / 4);

            if (rect.top <= playPosition && rect.bottom > playPosition) {
                player.playVideo();
            }
            else if (rect.top <= pausePosition || rect.top > windowHeight || rect.bottom < 0) {
                player.pauseVideo();
            }
        }
    });
};

async function chatBotABTest() {
    const api = '/tutor-abtest-service/abtest/log?event=ehanlin_cm_webchat_line';
    const res = await fetch(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).catch(console.error).then(res => res.json());

    if(res && res.success){
        if(res.result && res.result.variant) return res.result.variant.id === 'webchat'
    }
    return false;
}

$(window).ready(function(){
    // 進入頁面
    pageGA("index","首頁","")
    $('.question').click(function () {
        $(this).next('.answer').toggleClass('dn');
    });

    $('#moreCourseLink').click(function(){
        pageGA("立即試用課程", "立即試用課程",'/app/online-trial/trial-list.html#all&all&all&all')
    })

    $('#keyWordLink').click(function(){
        pageGA("小中高知識點關鍵字&index=2", "小中高知識點關鍵字",'/app/keyword/')
    })
    $('#courseUseLink').click(function (){
        pageGA("課程使用說明&index=2", "課程使用說明",'/infos/courseuse/')
    })
    $('#lineInfoLink').click(function() {
        pageGA("學習通知&index=2", "學習通知",'/infos/learningnotify/?utm_source=mainpage&utm_medium=banner&utm_campaign=learningnotify')
    })

    $('#userRankLink').click(function (){
        pageGA("會員等級說明&index=2", '會員等級說明','/app/member-center/user-rank.html?utm_source=website&amp;utm_medium=button&amp;utm_campaign=footer')
    })

    chatBotABTest().then(isWebChat => {
        if(isWebChat) {
            // load webchat sdk
            window.BBAsyncInit = function() {
                BB.init({
                    appId: "page-44f22a3e26ae4cae9d4ae88a"
                })
            }
            window.addEventListener('message', (e) => {
                if(e.origin === 'https://chat.botbonnie.com' && (e.data && e.data.payload && e.data.payload.message === "start_conversation")) {
                    if(window.ga4Event) window.ga4Event('客服LineOrWebChat', {"type": "chatbot"});
                }
            });
            const script = document.createElement('script');
            script.src = 'https://asset.botbonnie.com/sdk/sdk.js';
            script.async = true;
            document.body.appendChild(script);
        } else {
            const lineBtn = $('.floatbtn .line');
            lineBtn.removeClass('dn');
            lineBtn.click(function(){
                if(window.ga4Event) window.ga4Event('客服LineOrWebChat', {"type": "line"});
            });
        }
    })

    const nav = document.querySelector('eh-navbar');
    if(nav && nav.hideCartIcon) nav.hideCartIcon();
})
